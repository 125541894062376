import React from "react";
import "../css/App.css";
function Datenschutz() {
  return (
    <div className="home-container">
      <h2>Datenschutzerklärung</h2>
    </div>
  );
}

export default Datenschutz;
